<script setup>

import { KNOLIA } from "@/knolia";
import { ref, onMounted, onUpdated } from "vue";
import { Gems } from "../gems";

const subordinates = ref([])
const activities = ref([])
const subordinateActivities = ref([])
const userData = ref([])
const currentSubordinate = ref({ name: null, id: null })




async function GetSubordinateActivities(subordinateName, subordinateId) {
    currentSubordinate.value.name = subordinateName
    currentSubordinate.value.id = subordinateId

    KNOLIA.SetRequestedUserId(subordinateId)

    const response = await KNOLIA.CallBackEnd('post', 'get-subordinate-activities', { params: { user_id: subordinateId } })
    activities.value = response?.data?.activities_to_add
    subordinateActivities.value = response?.data?.user_activities
    userData.value = response?.data?.user_data

}

async function ControlActivityStatus(activity) {
    await KNOLIA.CallBackEnd('post', 'control-activity-status', { params: { activity } })
}

async function SelectTaskAssignment(activity, task) {
    if(!activity.active) {
        Gems.Telegram('Habilite a atividade antes', 'tele-not-allowed')
        return;
    }

    await KNOLIA.CallBackEnd('post', 'set-outside-job-task-assignment', { params: { activity, task, currentSubordinate: currentSubordinate.value } })
}


onMounted(async () => {
    const responseSubordinates = await KNOLIA.CallBackEnd('get', 'get-operations', {
        params: {
            get: 'subordinates'
        }
    })
    subordinates.value = responseSubordinates.data
})

onUpdated(() => {
    document.querySelectorAll('.activity-status').forEach(elem => {
        Gems.Tooltip(elem, 'Ativar/Desativar atividade', 'tooltip')
    })
})

</script>

<template>
    <div class="splitter page-container">
        <div id="first" class="bottom">
            <div id="div-subordinates-container">
                <div id="div-subordinates-title">
                    <h2>Subordinados</h2>
                </div>

                <div class="div-column-container scrollbar">
                    <div class="div-row" :class="{ 'bg-alt-line': currentSubordinate.id == subordinate.user_id }"
                        v-for="subordinate in subordinates" :key="subordinate.user_id"
                        @click="GetSubordinateActivities(subordinate.name, subordinate.user_id)">
                        <span class="line-clamp line-clamp-1" @mouseover="Gems.TooltipIfNeeded($event.target)">{{
                            subordinate.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div id="second" class="bottom">
            <div id="div-activity-container" @dragenter.prevent @dragover.prevent @drop="SetActivityToJob($event)">
                <div id="div-activity-title">
                    <h2>Atividades [{{ currentSubordinate.name ?? 'Escolha um subordinado' }}]</h2>
                </div>

                <div class="div-column-container scrollbar">
                    <div v-if="currentSubordinate.id">
                        <li class="job-title">Função: {{ userData?.job_desig }}</li>
                        <div v-for="activity in subordinateActivities" :key="activity.id">
                            <div class="div-row-activity">
                                <input class="activity-status cursor-pointer" type="checkbox" v-model="activity.active"
                                    @change="ControlActivityStatus(activity)">
                                <label for="">{{ activity.designation }}</label>
                                <i class="fas show-children cursor-pointer"
                                    :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"
                                    @click="activity.showChildren = !activity.showChildren"></i>
                            </div>

                            <div class="div-tasks" v-show="activity.showChildren">
                                <div class="div-task-container div-row-activity" v-for="task in activity.children"
                                    :key="task.id">
                                    <div class="div-task line-clamp line-clamp-1"
                                        @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                                    <select class="cursor-pointer ddl-alt-padding" :class="!draggable ? 'job-task' : ''"
                                        :value="task.selected_assignment" disabled>
                                        <option :value="0"> Não atribuida </option>
                                        <option v-for="assignment in task.assignment_levels" :key="assignment.level"
                                            :value="assignment.level">
                                            {{ assignment.designation }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <li>Fora do âmbito da função</li>
                        <div v-for="activity in activities" :key="activity.id">
                            <div class="div-row-activity">
                                <input class="activity-status cursor-pointer" type="checkbox" v-model="activity.active"
                                    @change="ControlActivityStatus(activity)">
                                <label for="">{{ activity.designation }}</label>
                                <i class="fas show-children cursor-pointer"
                                    :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"
                                    @click="activity.showChildren = !activity.showChildren"></i>
                            </div>

                            <div class="div-tasks" v-show="activity.showChildren">
                                <div class="div-task-container div-row-activity" v-for="task in activity.children"
                                    :key="task.id">
                                    <div class="div-task line-clamp line-clamp-1"
                                        @mouseover="Gems.TooltipIfNeeded($event.target)">{{ task.designation }}</div>

                                    <select class="cursor-pointer ddl-alt-padding" :class="!draggable ? 'job-task' : ''"
                                        v-model="task.selected_assignment" @change="SelectTaskAssignment(activity, task)">
                                        <option :value="null"> Não atribuida </option>
                                        <option v-for="assignment in task.assignment_levels" :key="assignment.level" :value="assignment.level">
                                            {{ assignment.designation }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.top,
.bottom {
    overflow-y: visible;
}

#div-subordinates-container,
#div-activity-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#div-subordinates-title h2 {
    margin-top: 0px;
}

.job-title {
    font-size: 1.2em;
    font-weight: 600;
}

.div-column-container {
    border: 1px solid;
    height: 100%;
    text-align: left;
    padding: 6px;
}

.div-row-activity,
.div-row {
    padding: 5px 15px;
    cursor: pointer;
    align-items: center;
    border-radius: 25px;
    display: flex;
    gap: 10px;
}

.div-row {
    justify-content: space-between;
}

.div-row:hover,
.div-row-activity:hover {
    background-color: var(--default-app-alt-line-color);
}

.div-row:not(:first-child),
.div-row-activity:not(:first-child) {
    margin-top: 8px;
}

.div-task-container {
    margin: 5px 0px 0px 10px;
}

.div-activity select,
.div-task-container select {
    background-color: var(--default-app-alt-line-color);
}

.div-activity,
.div-task-container {
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
}
</style>