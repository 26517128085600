<script setup>
import { onMounted, ref } from "vue";
import { useTreeStore } from '@/store/modules/trees.js';
import { KNOLIA } from "@/knolia";
import DepthTasksElement from "@/components/DepthTasksElement";
import MetaContainer from "@/components/MetaContainer";
import ActivitiesList from "@/components/ActivitiesList";
import SkillsTable from "@/components/SkillsTable";
import { onBeforeRouteLeave } from "vue-router";
import { Gems } from "@/gems";
import TreeType from "@/enums/TreeType"

const store = useTreeStore()
const searchText = ref("")
const activitiesArr = ref([])

function SearchInList() {
  store.filterLists({type: store.ACTIVITIES, searchText: searchText.value})
  store.filterLists({type: store.SKILLS, searchText: searchText.value})
}

async function NewMeta() {
  await KNOLIA.CallBackEnd("post", "control-tree-element", {
    update: "meta",
    treeType: TreeType.TASKS,
    name: "Novo Elemento",
  })

  store.GetTasksDepth()
}

function SelectMeta(event, column) {
  for (let column in store.Tree) {
    store.Tree[column]["selected"] = false;
  }
  if (event[1]) {
    column.selected = true;
    store.setSelectedMeta(column);
  } else {
    store.setSelectedMeta([]);
  }
}

onMounted(() => {
  store.GetTasksDepth();

  store.GetActivities();

  store.GetSkills({ treeType: "tasks" });

  Gems.Tooltip(document.getElementById("newMeta"), "Novo elemento", "tooltip");
  KNOLIA.ResizeSplitScreen(document.getElementById("separator"), "V");
});

onBeforeRouteLeave(() => {
  store.resetTree();
});
</script>

<template>
  <div class="splitter page-container">
    <div id="first" class="top">
      <div class="filter">
        <input type="text" v-model="searchText" placeholder="Filtrar..." @keyup="SearchInList"/>
      </div>
      <div class="lists scrollbar">
        <ActivitiesList :elements="activitiesArr" />
        <SkillsTable />
      </div>
    </div>
    <div id="separator" class="separator"></div>
    <div id="second" class="bottom scrollbar">
      <div class="table-container">
        <div>
          <section class="meta-comp-section">
            <div>
              <div class="add-meta-comp-container">
                <span class="meta-title">META AGRUPAMENTOS</span>
                <i id="newMeta" class="fa fa-plus-circle i-add-elem" @click="NewMeta"></i>
              </div>
            </div>
            <div class="meta-comp-container">
              <span v-for="(column, index) in store.Tree" :key="index" class="meta-comp-item">
                <DepthTasksElement
                  :element="column"
                  :parent="column.id"
                  :meta_id="column.elem_id"
                  :is_meta="true"
                  @select-meta="SelectMeta($event, column)"
                />
              </span>
            </div>
          </section>
        </div>
        <MetaContainer meta-type="tasks" />
      </div>
    </div>
  </div>
</template>

<style scoped>

.table-container {
  max-width: 80vw;
  display: flex;
  margin-top: 10px;
}

.top {
  display: flex;
  flex-direction: column;
  /* width: 70%; */
}

.lists {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.lists section {
  flex: 1;
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  max-width: max-content;
  min-width: 1010px;
}

.btn-filter {
  height: 32px;
  line-height: 1px;
  flex: 1;
}

.filter input {
  flex: 3;
}

.filter input::placeholder {
  color: var(--default-font-color);
}

.meta-comp-container {
  display: flex;
  flex-direction: column;
}

.meta-comp-container span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meta-comp-item {
  text-align: center;
  margin-bottom: 1px;
}

.meta-comp-section {
  padding: 1px 3px;
  width: 250px;
  min-height: 100%;
  text-align: center;  
  margin-top: 55px;
}

.i-add-elem {
  margin-left: 10px;
  cursor: pointer;
}

.meta-title {
  color: white;
}
</style>
